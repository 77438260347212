import React, { useEffect, useState } from 'react'
import PagesIndex from '../../PagesIndex';
import Index from '../../Index';
import { useLocation } from 'react-router-dom';

export default function Sidebar(props) {

      const sidebarLink = [
            {
                  name: "Dashboard",
                  link: "/admin/dashboard",
                  image: PagesIndex.Svg.dashboard
            },
            {
                  name: "Tags",
                  link: "/admin/tags",
                  image: PagesIndex.Svg.userlist
            },
            {
                  name: "Stories",
                  link: "/admin/stories",
                  image: PagesIndex.Svg.userlist
            },
            {
                  name: "Books",
                  link: "/admin/books",
                  image: PagesIndex.Svg.userlist
            },
            {
                  name: "Blogs",
                  link: "/admin/blogs",
                  image: PagesIndex.Svg.userlist
            },
            {
                  name: "Questions",
                  link: "/admin/questions",
                  image: PagesIndex.Svg.userlist
            },
            {
                  name: "Doctors",
                  link: "/admin/doctors",
                  image: PagesIndex.Svg.userlist
            },
            {
                  name: "FAQ",
                  link: "/admin/faq",
                  image: PagesIndex.Svg.userlist
            },
            {
                  name: "Appointment",
                  link: "/admin/appointment",
                  image: PagesIndex.Svg.userlist
            },
            {
                  name: "Downloads",
                  link: "/admin/download",
                  image: PagesIndex.Svg.userlist
            },
            // {
            //       name: "Category List",
            //       link: "/admin/category-list",
            //       image: PagesIndex.Svg.userlist
            // },
            // {
            //       name: "Sub Category List",
            //       link: "/admin/sub-category-list",
            //       image: PagesIndex.Svg.userlist
            // },
            // {
            //       name: "Product List",
            //       link: "/admin/product-list",
            //       image: PagesIndex.Svg.userlist
            // },
            // {
            //       name: "Slider mangement",
            //       link: "/admin/slider-mangement",
            //       image: PagesIndex.Svg.userlist
            // },
            // {
            //       name: "Banner mangement",
            //       link: "/admin/banner-mangement",
            //       image: PagesIndex.Svg.userlist
            // },
            // {
            //       name: "Offer mangement",
            //       link: "/admin/offer-mangement",
            //       image: PagesIndex.Svg.userlist
            // },
      ]
      const { pathname } = useLocation();
      const [open, setOpen] = React.useState(true);
      const [screen, setScreen] = useState('');
      const handleClickAccount = () => {
            setOpen(!open);
      };

      useEffect(() => {
            if (window.screen.width < 768) {
                  setScreen('Mobile')
            } else {
                  setScreen('Desktop')
            }
      }, [window.screen.availHeight])

      return (
            <>

                  <Index.Box className={`admin-sidebar-main ${(screen == 'Mobile' ? !props.open : props.open) ? "active" : ""}`}>
                        <Index.Box className="admin-sidebar-inner-main">
                              <Index.Box className="admin-sidebar-logo-main">
                                    <img src={PagesIndex.Png.logo} className="admin-sidebar-logo" alt='logo' />
                                    <Index.Button onClick={() => {
                                          props.setOpen(!props.open)
                                          document.body.classList[props.open ? "add" : "remove"](
                                                "body-overflow"
                                          );
                                    }} className="sidebar-close-btn">
                                          <img src={PagesIndex.Svg.close} className="close-icon" alt='logo' />
                                    </Index.Button>
                              </Index.Box>

                              <Index.Box className="admin-sidebar-list-main">
                                    <Index.List className="admin-sidebar-list">
                                          {sidebarLink.map((e) => {

                                                return (
                                                      <Index.ListItem className="admin-sidebar-listitem">
                                                            <Index.Link to={e.link} className={`admin-sidebar-link ${pathname === e.link ? "active" : ""}`}>
                                                                  <img src={e.image} alt='sidebar icon' className='admin-sidebar-icons' />{e.name}
                                                            </Index.Link>
                                                      </Index.ListItem>
                                                )
                                          })}


                                          {/* <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Link to='/admin/category-list' className="admin-sidebar-link">
                                                      <img src={PagesIndex.Svg.userlist} alt='sidebar icon' className='admin-sidebar-icons' />Category List
                                                </Index.Link>
                                          </Index.ListItem> */}

                                          {/* <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Link to='/admin/user-card' className="admin-sidebar-link">
                                                      <img src={PagesIndex.Svg.usercard} alt='sidebar icon' className='admin-sidebar-icons' />User Card
                                                </Index.Link>
                                          </Index.ListItem>

                                          <Index.ListItem className="admin-sidebar-listitem">
                                                <Index.Link to='/admin/edit-profile' className="admin-sidebar-link">
                                                      <img src={PagesIndex.Svg.editpage} alt='sidebar icon' className='admin-sidebar-icons' />Edit Profile
                                                </Index.Link>
                                          </Index.ListItem> */}

                                          {/* <Index.ListItem className='admin-sidebar-listitem admin-submenu-listitem-main' onClick={handleClickAccount}>
                                                <Index.Link className='admin-sidebar-link'>
                                                      <img src={PagesIndex.Svg.changepassword} alt='sidebar icon' className='admin-sidebar-icons' />   CMS
                                                      {open ? <Index.ExpandMore className='expandmore-icon' /> : <Index.ExpandLess className='expandless-icon' />}
                                                      <Index.Box className='submenu-main'>
                                                            <Index.Collapse in={open} timeout="auto" unmountOnExit className='submenu-collapse'>
                                                                  <Index.List component="div" disablePadding className='admin-sidebar-submenulist'>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Link className='admin-sidebar-link  active'>
                                                                                    About
                                                                              </Index.Link>
                                                                        </Index.ListItem>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Link className='admin-sidebar-link '>
                                                                                    Terms & Conditions
                                                                              </Index.Link>
                                                                        </Index.ListItem>
                                                                        <Index.ListItem className='admin-sidebar-listitem'>
                                                                              <Index.Link className='admin-sidebar-link '>
                                                                                    Privacy Policy
                                                                              </Index.Link>
                                                                        </Index.ListItem>
                                                                  </Index.List>
                                                            </Index.Collapse>
                                                      </Index.Box>
                                                </Index.Link>
                                          </Index.ListItem> */}
                                    </Index.List>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>
            </>
      )
}
