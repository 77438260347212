import axios from "axios";
import { toast } from "react-toastify";

// live--

// const API_ENDPOINT = "https://api.vittzios.com/api";
// const IMG_URL = "https://api.vittzios.com/api/images/"

// const API_ENDPOINT = "http://localhost:3003/api/";
// const IMG_URL = "http://localhost:3003/api/images/"

const API_ENDPOINT = process.env.REACT_APP_BASE_URL;
const IMG_URL = process.env.REACT_APP_IMG_URL

// const API_ENDPOINT = "http://91.108.110.25:3003/api/";
// const IMG_URL = "http://91.108.110.25:3003/api/images/"

//local--
// const API_ENDPOINT = "http://192.168.29.136:3027/api/";

// const API_ENDPOINT = "http://localhost:3027/api/";
// const API_IMAGE_URL_ENDPOINT = process.env.REACT_APP_IMAGE_URL;

const dataService = axios.create({
  baseURL: API_ENDPOINT,
});

dataService.interceptors.request.use(
  (config) => {
    config.headers.auth = localStorage.getItem("token");
    return config;
  },
  (error) => {
    return error;
  }
);

dataService.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    toast.error(error.response.data.message, {
      toastId: "1"
    })
    return Promise.reject(error);
  }
);

export { dataService, IMG_URL };
