import { SwipeableDrawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { dataService, IMG_URL } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { Formik, FieldArray } from "formik";
import { addBlogSchema } from "../../../../validation/validation";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, FontColor, List } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import { useNavigate } from "react-router-dom";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "600px",
  overflow: "auto"
};

// for table data

function createData(name, address, city, number, stutus, action) {
  return { name, address, city, number, stutus, action };
}

const rows = [
  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),
];

const blogTypes = ["ExploreByTopic", "DepthGuides"];

const contentType = ["Video", "VideoWithText"]

export default function Blogs() {
  const navigate = useNavigate()
  const [age, setAge] = React.useState("");
  const [editorData, setEditorData] = useState('');

  const [initialValues, setInitialValues] = useState({
    tagsId: [],
    type: "",
    contentType: "",
    videoUrl: "",
    authorName: "",
    reviewDate: "",
    title: "",
    text: "",
    bookId: null,
    storyId: null,
    learnPoints: [{ name: "" }],
    doctorsSuggetionTitle: "",
    doctorsSuggetionText: "",
    dynamicSection: [{
      title: "",
      text: "",
      color: "#000000",
      content: ""
    }]
  });

  const [categoryListData, setCategoryListData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [storiesData, setStoriesData] = useState([]);
  const [bokksData, setBokksData] = useState([]);

  const [deleteDataId, setDeleteDataId] = useState("")

  // add user modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => {
    navigate(`${`${id}`}`)
  };
  const handleClose = () => setOpen(false);

  // delete modal
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Index.Box
      className="filter-main"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Index.Box>
  );

  const deleteData = () => {
    dataService.delete(Api.DeleteBlogs + "?id=" + deleteDataId).then(() => {
      toast.success("Delete");
      handleCloseDelete();
      getAllCategory();
    })
  }

  const submitForm = (data) => {
    const formData = new FormData();

    for (let key in data) {
      if (Array.isArray(data[key]) && data[key].every(item => typeof item === 'object')) {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    }

    if (initialValues?._id) {
      formData.append('id', initialValues?._id);
    }

    dataService
      .post(Api.AddBlogs, formData)
      .then((e) => {
        toast.success("Added");
        getAllCategory();
        handleClose();
        window.location.reload(true);
      })
      .catch((e) => {
        // Handle error
      });
  };


  const getAllCategory = () => {
    dataService
      .get(Api.BlogsList)
      .then(({ data }) => {
        setCategoryListData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllTags = () => {
    dataService
      .get(Api.TagsList)
      .then(({ data }) => {
        setTagsData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllStories = () => {
    dataService
      .get(Api.StoryList)
      .then(({ data }) => {
        setStoriesData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllBooks = () => {
    dataService
      .get(Api.BooksList)
      .then(({ data }) => {
        setBokksData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getAllCategory();
    // getAllTags();
    // getAllStories()
    // getAllBooks()
  }, []);

  return (
    <>
      <Index.Box className="dashboard-content user-list-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Blogs List
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="filter-main">
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Index.Box className="export-btn-main border-btn-main">
                    <Index.Button
                      className="export-btn border-btn"
                    // onClick={toggleDrawer(anchor, true)}
                    >
                      <img
                        src={PagesIndex.Svg.filter}
                        className="down-icon"
                        alt="download icon"
                      />
                      Filter
                    </Index.Button>
                  </Index.Box>
                  <SwipeableDrawer
                    className="filter-main"
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Index.Box className="filter-header">
                      <Index.Typography className="filter-title">
                        Filter
                      </Index.Typography>
                      <img
                        src={PagesIndex.Png.close}
                        className="filter-close-icon"
                        onClick={toggleDrawer(anchor, false)}
                      />
                    </Index.Box>
                    <Index.Box className="filter-inner-main">
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          City
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Status
                        </Index.FormHelperText>
                        <Index.Box className="checkbox-main filter-checkbox-main input-box">
                          <Index.FormControlLabel
                            control={<Index.Checkbox defaultChecked />}
                            label="Active"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Deactivate"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Pending"
                            className="checkbox-lable"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-footer">
                      <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                        <Index.Button className="border-btn filter-cancel-btn">
                          Cancel
                        </Index.Button>
                        <Index.Button className="btn-primary filter-btn">
                          Filter
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Index.Box>
            <Index.Box className="userlist-inner-btn-flex">
              <Index.Box className="export-btn-main border-btn-main">
                <Index.Button
                  className="export-btn border-btn"
                // onClick={handleOpenDelete}
                >
                  <img
                    src={PagesIndex.Svg.down}
                    className="down-icon"
                    alt="download icon"
                  />
                  Export
                </Index.Button>
              </Index.Box>
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={() => handleOpen("new")}
                >
                  <img
                    src={PagesIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Author
                            </Index.TableCell>
                            {/* <Index.TableCell component='th' variant='th' className='table-th'>CITY</Index.TableCell>
                                                                                    <Index.TableCell component='th' variant='th' className='table-th'>NUMBER</Index.TableCell> */}
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ACTION
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {categoryListData?.map((row) => (
                            <Index.TableRow
                              key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row.title}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row.authorName}
                              </Index.TableCell>
                              {/* <Index.TableCell component='td' variant='td' className='table-td'>{row.city}</Index.TableCell>
                                                                                          <Index.TableCell component='td' variant='td' className='table-td'>{row.number}</Index.TableCell> */}
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.isActive ? "Active" : "In-Active"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                <Index.Box className="userdata-btn-flex">
                                  <Index.Button
                                    onClick={() => {
                                      // const data = { ...row };
                                      // for (let key in data) {
                                      //   if (typeof data[key] === 'string') {
                                      //     try {
                                      //       data[key] = JSON.parse(data[key]);
                                      //     } catch (e) {
                                      //       console.log(`Error parsing key: ${key}`, e);
                                      //       data[key] = data[key];
                                      //     }
                                      //   }
                                      // }
                                      // setInitialValues(data);
                                      handleOpen(row?._id);
                                    }}

                                  >
                                    <img src={PagesIndex.Svg.blueedit}></img>
                                  </Index.Button>
                                  <Index.Button
                                    onClick={() => {
                                      setDeleteDataId(row._id)
                                      handleOpenDelete()
                                    }}
                                  >
                                    <img src={PagesIndex.Svg.trash}></img>
                                  </Index.Button>
                                  {/* <Index.Button
                                    onClick={() => {

                                      handleOpen();
                                    }}
                                  >
                                    <img src={PagesIndex.Svg.yelloweye}></img>
                                  </Index.Button> */}
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>
                          ))}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-main">
                    <Index.Pagination
                      count={3}
                      variant="outlined"
                      shape="rounded"
                      className="pagination"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-ingredients"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">

        </Index.Box>
      </Index.Modal >

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main">
            <img src={PagesIndex.Svg.closecircle} className="user-circle-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to delete these records? This process cannot be
            undone.
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseDelete}
            >
              Cancel
            </Index.Button>
            <Index.Button onClick={() => deleteData()} className="modal-delete-btn modal-btn">
              Delete
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
