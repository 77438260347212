import { SwipeableDrawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { dataService, IMG_URL } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { Formik } from "formik";
import { addQuestionSchema } from "../../../../validation/validation";
import { toast } from "react-toastify";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "600px",
  overflow: "auto"
};

// for table data

function createData(name, address, city, number, stutus, action) {
  return { name, address, city, number, stutus, action };
}

const rows = [
  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={PagesIndex.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={PagesIndex.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={PagesIndex.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),
];

export default function Questions() {
  const [age, setAge] = React.useState("");

  const handleChangedropdown = (event) => {
    setAge(event.target.value);
  };

  const [initialValues, setInitialValues] = useState({
    question: "",
    answer: "",
    answerDate: "",
    diagnosis: "",
    author: "",
    tagsId: [],
  });

  const [categoryListData, setCategoryListData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [deleteDataId, setDeleteDataId] = useState("")

  // add user modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // delete modal
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Index.Box
      className="filter-main"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Index.Box>
  );

  const deleteData = () => {
    dataService.delete(Api.DeleteQuesttion + "?id=" + deleteDataId).then(() => {
      toast.success("Delete");
      handleCloseDelete();
      getAllCategory();
    })
  }

  const submitForm = (data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key])
    }
    if (initialValues?._id) {
      formData.append('id', initialValues?._id)
    }
    dataService
      .post(Api.AddQuesttion, formData)
      .then((e) => {
        toast.success("Added");
        getAllCategory();
        handleClose();
        window.location.reload(true);
      })
      .catch((e) => {
      });
  };

  const getAllCategory = () => {
    dataService
      .get(Api.QuesttionList)
      .then(({ data }) => {
        setCategoryListData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllTags = () => {
    dataService
      .get(Api.TagsList)
      .then(({ data }) => {
        setTagsData(data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getAllCategory();
    getAllTags();
  }, []);

  return (
    <>
      <Index.Box className="dashboard-content user-list-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Questions List
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="filter-main">
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Index.Box className="export-btn-main border-btn-main">
                    <Index.Button
                      className="export-btn border-btn"
                    // onClick={toggleDrawer(anchor, true)}
                    >
                      <img
                        src={PagesIndex.Svg.filter}
                        className="down-icon"
                        alt="download icon"
                      />
                      Filter
                    </Index.Button>
                  </Index.Box>
                  <SwipeableDrawer
                    className="filter-main"
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Index.Box className="filter-header">
                      <Index.Typography className="filter-title">
                        Filter
                      </Index.Typography>
                      <img
                        src={PagesIndex.Png.close}
                        className="filter-close-icon"
                        onClick={toggleDrawer(anchor, false)}
                      />
                    </Index.Box>
                    <Index.Box className="filter-inner-main">
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          City
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Status
                        </Index.FormHelperText>
                        <Index.Box className="checkbox-main filter-checkbox-main input-box">
                          <Index.FormControlLabel
                            control={<Index.Checkbox defaultChecked />}
                            label="Active"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Deactivate"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Pending"
                            className="checkbox-lable"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-footer">
                      <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                        <Index.Button className="border-btn filter-cancel-btn">
                          Cancel
                        </Index.Button>
                        <Index.Button className="btn-primary filter-btn">
                          Filter
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Index.Box>
            <Index.Box className="userlist-inner-btn-flex">
              <Index.Box className="export-btn-main border-btn-main">
                <Index.Button
                  className="export-btn border-btn"
                // onClick={handleOpenDelete}
                >
                  <img
                    src={PagesIndex.Svg.down}
                    className="down-icon"
                    alt="download icon"
                  />
                  Export
                </Index.Button>
              </Index.Box>
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={handleOpen}
                >
                  <img
                    src={PagesIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Questions
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Answer
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Date
                            </Index.TableCell>
                            {/* <Index.TableCell component='th' variant='th' className='table-th'>CITY</Index.TableCell>
                                                                                    <Index.TableCell component='th' variant='th' className='table-th'>NUMBER</Index.TableCell> */}
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ACTION
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {categoryListData?.map((row) => (
                            <Index.TableRow
                              key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row.question}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row.answer}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.answerDate}
                              </Index.TableCell>
                              {/* <Index.TableCell component='td' variant='td' className='table-td'>{row.city}</Index.TableCell>
                                                                                          <Index.TableCell component='td' variant='td' className='table-td'>{row.number}</Index.TableCell> */}
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.isActive ? "Active" : "In-Active"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                <Index.Box className="userdata-btn-flex">
                                  <Index.Button
                                    onClick={() => {
                                      setInitialValues(row);
                                      //   initialValues=row;
                                      handleOpen();

                                    }}
                                  >
                                    <img src={PagesIndex.Svg.blueedit}></img>
                                  </Index.Button>
                                  <Index.Button
                                    onClick={() => {
                                      setDeleteDataId(row._id)
                                      handleOpenDelete()
                                    }}
                                  >
                                    <img src={PagesIndex.Svg.trash}></img>
                                  </Index.Button>
                                  <Index.Button
                                    onClick={() => {

                                      handleOpen();
                                    }}
                                  >
                                    <img src={PagesIndex.Svg.yelloweye}></img>
                                  </Index.Button>
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>
                          ))}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-main">
                    <Index.Pagination
                      count={3}
                      variant="outlined"
                      shape="rounded"
                      className="pagination"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-ingredients"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          <Formik
            validationSchema={addQuestionSchema}
            initialValues={initialValues}
            onSubmit={submitForm}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isValid,
              dirty,
              touched,
              handleBlur,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>

                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add
                    {console.log(errors, "maulik570")}
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleClose}
                  />
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Select Tags (Min 2 tag select)
                  </Index.FormHelperText>
                  <Index.Box className="dropdown-box">
                    <Index.FormControl className="form-control drop-form-control">
                      <Index.Select
                        className="dropdown-select drop-select"
                        multiple
                        value={values.tagsId} // Ensure tagsId is an array
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="tagsId"
                        helperText={touched.tagsId && errors.tagsId}
                        error={Boolean(errors.tagsId && touched.tagsId)}
                        renderValue={(selected) =>
                          selected.map((tagId) =>
                            tagsData.find((item) => item._id === tagId)?.name
                          ).join(', ')
                        }
                      >
                        <Index.MenuItem value="" className="drop-menuitem">
                          Select tags
                        </Index.MenuItem>
                        {tagsData?.map((e) => (
                          <Index.MenuItem
                            key={e._id}
                            value={e._id}
                            className="drop-menuitem"
                          >
                            {e.name}
                          </Index.MenuItem>
                        ))}
                      </Index.Select>
                      {/* Optional icon for dropdown */}
                      {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Questions
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      value={values.question}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="question"
                      helperText={touched.question && errors.question}
                      error={Boolean(
                        errors.question && touched.question
                      )}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Answer
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    {/* <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      value={values.answer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="answer"
                      helperText={touched.answer && errors.answer}
                      error={Boolean(
                        errors.answer && touched.answer
                      )}
                    /> */}
                    <Index.TextField
                      fullWidth
                      value={values.answer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      multiline
                      rows={5}
                      maxRows={10}
                      variant="outlined"
                      name="answer"
                      helperText={touched.answer && errors.answer}
                      error={Boolean(errors.answer && touched.answer)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    AnswerDate
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.answerDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      name="answerDate"
                      helperText={touched.answerDate && errors.answerDate}
                      error={Boolean(errors.answerDate && touched.answerDate)}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Author
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      value={values.author}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                      name="author"
                      helperText={touched.author && errors.author}
                      error={Boolean(errors.author && touched.author)}
                    />
                  </Index.Box>
                </Index.Box>

                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Image (46 × 46 px)
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <img src={values?.questionImg ? URL.createObjectURL(values?.questionImg) : values.image ? IMG_URL + values.image : PagesIndex.Png.imgPre} height={"100px"} width={"100px"} />
                    <Index.Button
                      fullWidth
                      variant="contained"
                      component="label"
                      type="button"
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        id="questionImg" name="questionImg"
                        onChange={(event) => {
                          setFieldValue("questionImg", event.currentTarget.files[0]);
                          event.target.files = null;
                          event.target.value = null;
                        }}
                      />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-user-btn-flex">
                  <Index.Box className="discard-btn-main border-btn-main">
                    <Index.Button type="reset" className="discard-user-btn border-btn">
                      Discard
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="save-btn-main border-btn-main">
                    <Index.Button
                      type="submit"
                      className="save-user-btn border-btn"
                    >
                      <img
                        src={PagesIndex.Svg.save}
                        className="user-save-icon"
                      ></img>
                      Save
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Modal >

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main">
            <img src={PagesIndex.Svg.closecircle} className="user-circle-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to delete these records? This process cannot be
            undone.
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseDelete}
            >
              Cancel
            </Index.Button>
            <Index.Button onClick={() => deleteData()} className="modal-delete-btn modal-btn">
              Delete
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
