import * as Yup from "yup";

export const adminLoginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email")
    // .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, "This is not a valid format")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),

  // .oneOf([Yup.ref("email"), ""], "Email must match"),

  password: Yup.string()
    .required("Please enter your password")
    .min(8, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/, "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."),
});

export const addTagsSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter your tag name"),
});

export const addStorySchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter your Name"),
  age: Yup.string()
    .required("Please enter your age"),
  status: Yup.string()
    .required("Please enter your status"),
  diagnosis: Yup.string()
    .required("Please enter your diagnosis"),
  rating: Yup.string()
    .required("Please enter your rating"),
  quotes: Yup.string()
    .required("Please enter your quotes"),
  plans: Yup.string()
    .required("Please enter your plans"),
});

export const addBlogSchema = Yup.object().shape({
  authorName: Yup.string()
    .required("Please enter author Name"),
  bookId: Yup.string()
    .required("Please select book"),
  contentType: Yup.string()
    .required("Please select content type"),
  tagsId: Yup.array()
    .required("Please enter your tags"),

  doctorsSuggetionText: Yup.string()
    .required("Please enter doctors suggetion title"),
  doctorsSuggetionTitle: Yup.string()
    .required("Please select book"),
  contentType: Yup.string()
    .required("Please select content type"),
  tagsId: Yup.array()
    .required("Please enter your tags"),
});

export const addBooksSchema = Yup.object().shape({
  tagsId: Yup.array()
    .required("Please enter your tags"),
  name: Yup.string()
    .required("Please enter your name"),
  bannerHeading: Yup.string()
    .required("Please enter your bannerHeading"),
  bannerDescription: Yup.string()
    .required("Please enter your bannerDescription"),
  reviewBy: Yup.string()
    .required("Please enter your reviewBy"),
  reviewDate: Yup.string()
    .required("Please enter your reviewDate"),
  rating: Yup.string()
    .required("Please enter your rating"),
  languages: Yup.string()
    .required("Please enter your languages"),
  description: Yup.string()
    .required("Please enter your description"),
});

export const addQuestionSchema = Yup.object().shape({
  question: Yup.string()
    .required("Please enter your question"),
  answer: Yup.string()
    .required("Please enter your answer"),
  answerDate: Yup.string()
    .required("Please enter your answerDate"),
  author: Yup.string()
    .required("Please enter your author"),
  tagsId: Yup.array()
    .required("Please enter your tags"),
});

export const addFaqSchema = Yup.object().shape({
  category: Yup.string()
    .required("Please select category"),
  question: Yup.string()
    .required("Please enter your question"),
  answer: Yup.string()
    .required("Please enter your answer"),
});

export const addDoctorsSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter your name"),
  designation: Yup.string()
    .required("Please enter your designation"),
  type: Yup.string()
    .required("Please select type"),
});

export const addCategorySchema = Yup.object().shape({
  categoryName: Yup.string()
    .required("Please enter your categoryName"),
  description: Yup.string()
    .required("Please enter your description"),
  sequence: Yup.string()
    .required("Please enter your sequence"),
});

export const addSubCategorySchema = Yup.object().shape({
  categoryId: Yup.string()
    .required("Please enter your categoryName"),
  description: Yup.string()
    .required("Please enter your description"),
  subCategoryName: Yup.string()
    .required("Please enter your subCategoryName")
});

export const addProductSchema = Yup.object().shape({
  categoryId: Yup.string()
    .required("Please enter your categoryName"),
  ingredients: Yup.string()
    .required("Please enter your ingredients"),
  contains: Yup.string()
    .required("Please enter your contains"),
  useFor: Yup.string()
    .required("Please enter your useFor"),
  subCategoryId: Yup.string()
    .required("Please enter your subCategoryName"),
  name: Yup.string()
    .required("Please enter your name"),
  price: Yup.string()
    .required("Please enter your price"),
  availableQty: Yup.string()
    .required("Please enter your availableQty"),
  hsnCode: Yup.string()
    .required("Please enter your hsnCode")
});

export const addSliderSchema = Yup.object().shape({
  sliderName: Yup.string()
    .required("Please enter your sliderName"),
  description: Yup.string()
    .required("Please enter your password")
});

export const addBannerSchema = Yup.object().shape({
  categoryId: Yup.string()
    .required("Please enter your categoryName"),
  description: Yup.string()
    .required("Please enter your description"),
  bannerName: Yup.string()
    .required("Please enter your bannerName")
});

export const addCouponSchema = Yup.object().shape({
  promoCode: Yup.string()
    .required("Please enter your promoCode")
});