import { SwipeableDrawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { dataService, IMG_URL } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { Formik, FieldArray } from "formik";
import { addBlogSchema } from "../../../../validation/validation";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, FontColor, List, Link, Image, ImageUpload, FontSize } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

const blogTypes = ["ExploreByTopic", "DepthGuides"];

const contentType = ["Video", "VideoWithText"]

export const AddBlogs = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const [editorData, setEditorData] = useState('');
    const [loading, setLoader] = useState(true)

    const [initialValues, setInitialValues] = useState({
        tagsId: [],
        type: "",
        contentType: "",
        videoUrl: "",
        authorName: "",
        reviewDate: "",
        title: "",
        text: "",
        bookId: null,
        storyId: null,
        learnPoints: [{ name: "", scroll: "" }],
        doctorsSuggetionTitle: "",
        doctorsSuggetionText: "",
        dynamicSection: [{
            title: "",
            text: "",
            color: "#000000",
            content: "",
            scroll: ""
        }]
    });

    console.log(initialValues, "maulik50")

    const [categoryListData, setCategoryListData] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    console.log(tagsData, "maulik55")
    const [storiesData, setStoriesData] = useState([]);
    const [bokksData, setBokksData] = useState([]);

    const [deleteDataId, setDeleteDataId] = useState("")

    // add user modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // delete modal
    const [openDelete, setOpenDelete] = React.useState(false);
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);

    // filter
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Index.Box
            className="filter-main"
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        ></Index.Box>
    );

    const deleteData = () => {
        dataService.delete(Api.DeleteBlogs + "?id=" + deleteDataId).then(() => {
            toast.success("Delete");
            handleCloseDelete();
            getAllCategory();
        })
    }

    const submitForm = (data) => {
        const formData = new FormData();

        for (let key in data) {
            if (Array.isArray(data[key]) && data[key].every(item => typeof item === 'object')) {
                formData.append(key, JSON.stringify(data[key]));
            } else {
                formData.append(key, data[key]);
            }
        }

        if (initialValues?._id) {
            formData.append('id', initialValues?._id);
        }

        dataService
            .post(Api.AddBlogs, formData)
            .then((e) => {
                toast.success("Added");
                // getAllCategory();
                // handleClose();
                window.location.replace('/admin/blogs');
            })
            .catch((e) => {
                // Handle error
            });
    };


    const getAllCategory = () => {
        dataService
            .get(Api.BlogsList)
            .then(({ data }) => {
                setCategoryListData(data.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getAllTags = () => {
        dataService
            .get(Api.TagsList)
            .then(({ data }) => {
                setTagsData(data.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getAllStories = () => {
        dataService
            .get(Api.StoryList)
            .then(({ data }) => {
                setStoriesData(data.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getAllBooks = () => {
        dataService
            .get(Api.BooksList)
            .then(({ data }) => {
                setBokksData(data.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getSingleBlogs = (id) => {
        setLoader(true)
        dataService.get(Api.BlogsSingle + "?id=" + id).then(({ data }) => {
            console.log(data, "maulik183")
            // setBokksData(data.data);
            const obj = data = { ...(data?.data || {}) };
            // for (let key in data) {
            //     if (typeof data[key] === 'string') {
            //         try {
            //             data[key] = JSON.parse(data[key]);
            //         } catch (e) {
            //             console.log(`Error parsing key: ${key}`, e);
            //             data[key] = data[key];
            //         }
            //     }
            // }
            setInitialValues(obj)
            setTimeout(() => {
                setLoader(false)
            }, 1000)
        })
    }

    const uploadImage = (payload, oldImage) => {
        const formData = new FormData();
        formData.append('image', payload);
        formData.append('oldImage', oldImage)
        return dataService
            .post(Api.UploadSingle, formData)
            .then(({ data }) => {
                toast.success("Image uploaded");
                return data.data
            })
            .catch((e) => {
            });
    };

    useEffect(() => {
        // getAllCategory();
        getAllTags();
        getAllStories()
        getAllBooks()
    }, []);

    useEffect(() => {
        if (id && id !== "new") {
            getSingleBlogs(id)
        }
        if (id === "new") {
            setLoader(false)
        }
    }, [id])

    return (
        <>
            {
                !loading ? <Formik
                    // validationSchema={addBlogSchema}
                    initialValues={initialValues}
                    onSubmit={submitForm}
                    enableReinitialize={true}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                        isValid,
                        dirty,
                        touched,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched
                    }) => (
                        <form onSubmit={handleSubmit}>

                            <Index.Box className="modal-header">
                                <Index.Typography
                                    id="modal-modal-title"
                                    className="modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    Add
                                    {console.log(values, "maulik570")}
                                </Index.Typography>
                                <img
                                    src={PagesIndex.Svg.closeblack}
                                    className="modal-close-icon"
                                    onClick={handleClose}
                                />
                            </Index.Box>
                            <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                    Select Tags (Min 2 tag select)
                                </Index.FormHelperText>
                                <Index.Box className="dropdown-box">
                                    <Index.FormControl className="form-control drop-form-control">
                                        <Index.Select
                                            key={tagsData?.length}
                                            className="dropdown-select drop-select"
                                            multiple
                                            value={values.tagsId} // Ensure tagsId is an array
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="tagsId"
                                            helperText={touched.tagsId && errors.tagsId}
                                            error={Boolean(errors.tagsId && touched.tagsId)}
                                            renderValue={(selected) =>
                                                selected?.map((tagId) =>
                                                    tagsData?.find((item) => item._id === tagId)?.name
                                                ).join(', ')
                                            }
                                        >
                                            <Index.MenuItem value="" className="drop-menuitem">
                                                Select tags
                                            </Index.MenuItem>
                                            {tagsData?.map((e) => (
                                                <Index.MenuItem
                                                    key={e._id}
                                                    value={e._id}
                                                    className="drop-menuitem"
                                                >
                                                    {e.name}
                                                </Index.MenuItem>
                                            ))}
                                        </Index.Select>
                                        {/* Optional icon for dropdown */}
                                        {/* <span><img src={Index.Svg.instagram} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                                    </Index.FormControl>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                    Select Type
                                </Index.FormHelperText>
                                <Index.Box className="dropdown-box">
                                    <Index.FormControl className="form-control drop-form-control">
                                        <Index.Select
                                            className="dropdown-select drop-select"
                                            value={values.type}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="type"
                                            helperText={touched.type && errors.type}
                                            error={Boolean(errors.type && touched.type)}
                                        >
                                            <Index.MenuItem value="" className="drop-menuitem">
                                                Select type
                                            </Index.MenuItem>
                                            {blogTypes?.map((e) => (
                                                <Index.MenuItem
                                                    key={e}
                                                    value={e}
                                                    className="drop-menuitem"
                                                >
                                                    {e}
                                                </Index.MenuItem>
                                            ))}
                                        </Index.Select>
                                    </Index.FormControl>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                    Content Type
                                </Index.FormHelperText>
                                <Index.Box className="dropdown-box">
                                    <Index.FormControl className="form-control drop-form-control">
                                        <Index.Select
                                            className="dropdown-select drop-select"
                                            value={values.contentType}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="contentType"
                                            helperText={touched.contentType && errors.contentType}
                                            error={Boolean(errors.contentType && touched.contentType)}
                                        >
                                            <Index.MenuItem value="" className="drop-menuitem">
                                                Select content type
                                            </Index.MenuItem>
                                            {contentType?.map((e) => (
                                                <Index.MenuItem
                                                    key={e}
                                                    value={e}
                                                    className="drop-menuitem"
                                                >
                                                    {e}
                                                </Index.MenuItem>
                                            ))}
                                        </Index.Select>
                                    </Index.FormControl>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                    Main Image (328 × 437 px) show in slider
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                    <img src={values?.productMainImg ? URL.createObjectURL(values?.productMainImg) : values.mainImage ? IMG_URL + values.mainImage : PagesIndex.Png.imgPre} height={"100px"} width={"100px"} />
                                    <Index.Button
                                        fullWidth
                                        variant="contained"
                                        component="label"
                                        type="button"
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder=""
                                    >
                                        Upload
                                        <input
                                            type="file"
                                            hidden
                                            id="productMainImg" name="productMainImg"
                                            onChange={(event) => {
                                                setFieldValue("productMainImg", event.currentTarget.files[0]);
                                                event.target.files = null;
                                                event.target.value = null;
                                            }}
                                        />
                                    </Index.Button>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                    Top Image (515 × 621 px) show in banner for details page
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                    <img src={values?.productTopImage ? URL.createObjectURL(values?.productTopImage) : values.topImage ? IMG_URL + values.topImage : PagesIndex.Png.imgPre} height={"100px"} width={"100px"} />
                                    <Index.Button
                                        fullWidth
                                        variant="contained"
                                        component="label"
                                        type="button"
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder=""
                                    >
                                        Upload
                                        <input
                                            type="file"
                                            hidden
                                            id="productTopImage" name="productTopImage"
                                            onChange={(event) => {
                                                setFieldValue("productTopImage", event.currentTarget.files[0]);
                                                event.target.files = null;
                                                event.target.value = null;
                                            }}
                                        />
                                    </Index.Button>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                    video Url
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        fullWidth
                                        value={values.videoUrl}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder=""
                                        name="videoUrl"
                                        helperText={touched.videoUrl && errors.videoUrl}
                                        error={Boolean(errors.videoUrl && touched.videoUrl)}
                                    />
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                    Author Name
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        fullWidth
                                        value={values.authorName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder=""
                                        name="authorName"
                                        helperText={touched.authorName && errors.authorName}
                                        error={Boolean(errors.authorName && touched.authorName)}
                                    />
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                    Author Image
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                    <img src={values?.authorImagePayload ? URL.createObjectURL(values?.authorImagePayload) : values.authorImage ? IMG_URL + values.authorImage : PagesIndex.Png.imgPre} height={"100px"} width={"100px"} />
                                    <Index.Button
                                        fullWidth
                                        variant="contained"
                                        component="label"
                                        type="button"
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder=""
                                    >
                                        Upload
                                        <input
                                            type="file"
                                            hidden
                                            id="authorImagePayload" name="authorImagePayload"
                                            onChange={(event) => {
                                                setFieldValue("authorImagePayload", event.currentTarget.files[0]);
                                                event.target.files = null;
                                                event.target.value = null;
                                            }}
                                        />
                                    </Index.Button>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                    Review Date
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        fullWidth
                                        value={values.reviewDate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder=""
                                        name="reviewDate"
                                        helperText={touched.reviewDate && errors.reviewDate}
                                        error={Boolean(errors.reviewDate && touched.reviewDate)}
                                    />
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                    Title
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        fullWidth
                                        value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder=""
                                        name="title"
                                        helperText={touched.title && errors.title}
                                        error={Boolean(errors.title && touched.title)}
                                    />
                                </Index.Box>
                            </Index.Box>

                            {/* <Index.Box className="input-box modal-input-box">
              <Index.FormHelperText className="form-lable">
                Text
              </Index.FormHelperText>
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  value={values.text}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="fullWidth"
                  className="form-control"
                  placeholder=""
                  name="text"
                  multiline
                  rows={5}
                  maxRows={10}
                  variant="outlined"
                  helperText={touched.text && errors.text}
                  error={Boolean(errors.text && touched.text)}
                />
              </Index.Box>
            </Index.Box> */}

                            <FieldArray name="learnPoints">
                                {({ remove, push }) => (
                                    <div>
                                        {values?.learnPoints?.map((item, index) => (
                                            <div key={index} className="learnPoints">
                                                <Index.Typography mb={2} variant="h6">Learn Point {index + 1} {index ? (
                                                    <Index.Button size="small" endIcon={<RemoveCircleOutlineIcon />} variant="contained" onClick={() => remove(index)}>Remove Learn Point</Index.Button>
                                                ) : null}</Index.Typography>
                                                <Index.Box className="input-box modal-input-box">
                                                    <Index.FormHelperText className="form-label">Name</Index.FormHelperText>
                                                    <Index.Box className="form-group">
                                                        <Index.TextField
                                                            fullWidth
                                                            id="fullWidth"
                                                            className="form-control"
                                                            placeholder="Enter Learn Point"
                                                            value={item.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name={`learnPoints[${index}].name`}
                                                            helperText={
                                                                errors.learnPoints &&
                                                                errors.learnPoints[index] &&
                                                                touched.learnPoints &&
                                                                touched.learnPoints[index] &&
                                                                errors.learnPoints[index].name
                                                            }
                                                            error={Boolean(
                                                                errors.learnPoints &&
                                                                errors.learnPoints[index] &&
                                                                touched.learnPoints &&
                                                                touched.learnPoints[index] &&
                                                                errors.learnPoints[index].name
                                                            )}
                                                        />
                                                    </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="input-box modal-input-box">
                                                    <Index.FormHelperText className="form-label">Scroll Id (with hastag no space)</Index.FormHelperText>
                                                    <Index.Box className="form-group">
                                                        <Index.TextField
                                                            fullWidth
                                                            id="fullWidth"
                                                            className="form-control"
                                                            placeholder="Enter scroll id (EX. #point-1)"
                                                            value={item.scroll}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name={`learnPoints[${index}].scroll`}
                                                            helperText={
                                                                errors.learnPoints &&
                                                                errors.learnPoints[index] &&
                                                                touched.learnPoints &&
                                                                touched.learnPoints[index] &&
                                                                errors.learnPoints[index].scroll
                                                            }
                                                            error={Boolean(
                                                                errors.learnPoints &&
                                                                errors.learnPoints[index] &&
                                                                touched.learnPoints &&
                                                                touched.learnPoints[index] &&
                                                                errors.learnPoints[index].scroll
                                                            )}
                                                        />
                                                    </Index.Box>
                                                </Index.Box>
                                                {
                                                    (values?.learnPoints?.length - 1) === index ?
                                                        <Index.Button
                                                            size="small"
                                                            endIcon={<AddCircleOutlineIcon />}
                                                            variant="contained"
                                                            onClick={() =>
                                                                push({
                                                                    name: "",
                                                                    scroll: ""
                                                                })
                                                            }
                                                        >
                                                            Add Learn Point
                                                        </Index.Button> : ""}
                                            </div>
                                        ))}

                                    </div>
                                )}
                            </FieldArray>

                            <Index.Box className="input-box modal-input-box" mt={2}>
                                <Index.FormHelperText className="form-lable">
                                    Doctor suggetion title
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder=""
                                        value={values.doctorsSuggetionTitle}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="doctorsSuggetionTitle"
                                        helperText={touched.doctorsSuggetionTitle && errors.doctorsSuggetionTitle}
                                        error={Boolean(
                                            errors.doctorsSuggetionTitle && touched.doctorsSuggetionTitle
                                        )}
                                    />
                                </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-box modal-input-box">
                                <Index.FormHelperText className="form-lable">
                                    Doctor suggetion text
                                </Index.FormHelperText>
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder=""
                                        value={values.doctorsSuggetionText}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="doctorsSuggetionText"
                                        helperText={touched.doctorsSuggetionText && errors.doctorsSuggetionText}
                                        error={Boolean(
                                            errors.doctorsSuggetionText && touched.doctorsSuggetionText
                                        )}
                                    />
                                </Index.Box>
                            </Index.Box>
                            <FieldArray name="dynamicSection">
                                {({ remove, push }) => (
                                    <div key={values?.dynamicSection?.length}>
                                        {values?.dynamicSection?.map((item, index) => (
                                            <div key={index} className="dynamicSection">
                                                <Index.Typography mb={2} variant="h4">Section {index + 1} {index ? (
                                                    <Index.Button size="small" endIcon={<RemoveCircleOutlineIcon />} variant="contained" onClick={() => remove(index)}>Remove Section</Index.Button>
                                                ) : null}</Index.Typography>
                                                <Index.Box className="input-box modal-input-box">
                                                    <CKEditor
                                                        key={index}
                                                        editor={ClassicEditor}
                                                        data={item.content}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setFieldValue(`dynamicSection[${index}].content`, data);
                                                        }}
                                                        onBlur={() => setFieldTouched(`dynamicSection[${index}].content`)}
                                                        config={{
                                                            toolbar: {
                                                                items: [
                                                                    'undo', 'redo', '|', 'bold', 'italic', '|',
                                                                    'heading', '|', 'bulletedList', 'numberedList', '|',
                                                                    'link', '|', 'fontColor', '|', 'fontSize'
                                                                ],
                                                            },
                                                            plugins: [
                                                                Bold, Essentials, Italic, Mention, Paragraph, Undo,
                                                                Heading, FontColor, List, Link, FontSize
                                                            ],
                                                        }}
                                                    />
                                                </Index.Box>

                                                <Index.Box className="input-box modal-input-box">
                                                    <Index.FormHelperText className="form-lable">Title</Index.FormHelperText>
                                                    <Index.Box className="form-group">
                                                        <Index.TextField
                                                            fullWidth
                                                            className="form-control"
                                                            placeholder=""
                                                            value={item.title}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name={`dynamicSection[${index}].title`}
                                                            helperText={
                                                                errors.dynamicSection &&
                                                                errors.dynamicSection[index] &&
                                                                touched.dynamicSection &&
                                                                touched.dynamicSection[index] &&
                                                                errors.dynamicSection[index].title
                                                            }
                                                            error={Boolean(
                                                                errors.dynamicSection &&
                                                                errors.dynamicSection[index] &&
                                                                touched.dynamicSection &&
                                                                touched.dynamicSection[index] &&
                                                                errors.dynamicSection[index].title
                                                            )}
                                                        />
                                                    </Index.Box>
                                                </Index.Box>

                                                <Index.Box className="input-box modal-input-box">
                                                    <Index.FormHelperText className="form-lable">Text</Index.FormHelperText>
                                                    <Index.Box className="form-group">
                                                        <Index.TextField
                                                            fullWidth
                                                            className="form-control"
                                                            placeholder=""
                                                            value={item.text}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name={`dynamicSection[${index}].text`}
                                                            helperText={
                                                                errors.dynamicSection &&
                                                                errors.dynamicSection[index] &&
                                                                touched.dynamicSection &&
                                                                touched.dynamicSection[index] &&
                                                                errors.dynamicSection[index].text
                                                            }
                                                            error={Boolean(
                                                                errors.dynamicSection &&
                                                                errors.dynamicSection[index] &&
                                                                touched.dynamicSection &&
                                                                touched.dynamicSection[index] &&
                                                                errors.dynamicSection[index].text
                                                            )}
                                                        />
                                                    </Index.Box>
                                                </Index.Box>

                                                {/* Add color code input */}
                                                <Index.Box className="input-box modal-input-box">
                                                    <Index.FormHelperText className="form-lable">Color Code</Index.FormHelperText>
                                                    <Index.Box className="form-group">
                                                        <Index.TextField
                                                            fullWidth
                                                            type="color" // Set type to color for color picker
                                                            className="form-control"
                                                            value={item.color || "#000000"} // Default color value
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name={`dynamicSection[${index}].color`} // Update name to match your form state
                                                            helperText={
                                                                errors.dynamicSection &&
                                                                errors.dynamicSection[index] &&
                                                                touched.dynamicSection &&
                                                                touched.dynamicSection[index] &&
                                                                errors.dynamicSection[index].color
                                                            }
                                                            error={Boolean(
                                                                errors.dynamicSection &&
                                                                errors.dynamicSection[index] &&
                                                                touched.dynamicSection &&
                                                                touched.dynamicSection[index] &&
                                                                errors.dynamicSection[index].color
                                                            )}
                                                        />
                                                    </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="input-box modal-input-box">
                                                    <Index.FormHelperText className="form-label">Scroll Id (with hastag no space)</Index.FormHelperText>
                                                    <Index.Box className="form-group">
                                                        <Index.TextField
                                                            fullWidth
                                                            id="fullWidth"
                                                            className="form-control"
                                                            placeholder="Enter scroll id (EX. #point-1)"
                                                            value={item.scroll}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name={`dynamicSection[${index}].scroll`}
                                                            helperText={
                                                                errors.dynamicSection &&
                                                                errors.dynamicSection[index] &&
                                                                touched.dynamicSection &&
                                                                touched.dynamicSection[index] &&
                                                                errors.dynamicSection[index].scroll
                                                            }
                                                            error={Boolean(
                                                                errors.dynamicSection &&
                                                                errors.dynamicSection[index] &&
                                                                touched.dynamicSection &&
                                                                touched.dynamicSection[index] &&
                                                                errors.dynamicSection[index].scroll
                                                            )}
                                                        />
                                                    </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="input-box modal-input-box">
                                                    <Index.FormHelperText className="form-lable">
                                                        Select Book
                                                    </Index.FormHelperText>
                                                    <Index.Box className="dropdown-box">
                                                        <Index.FormControl className="form-control drop-form-control">
                                                            <Index.Select
                                                                className="dropdown-select drop-select"
                                                                value={item.bookId}
                                                                name={`dynamicSection[${index}].bookId`}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                helperText={
                                                                    errors.dynamicSection &&
                                                                    errors.dynamicSection[index] &&
                                                                    touched.dynamicSection &&
                                                                    touched.dynamicSection[index] &&
                                                                    errors.dynamicSection[index].bookId
                                                                }
                                                                error={Boolean(
                                                                    errors.dynamicSection &&
                                                                    errors.dynamicSection[index] &&
                                                                    touched.dynamicSection &&
                                                                    touched.dynamicSection[index] &&
                                                                    errors.dynamicSection[index].bookId
                                                                )}
                                                            >
                                                                <Index.MenuItem value="" className="drop-menuitem">
                                                                    Select book
                                                                </Index.MenuItem>
                                                                {bokksData?.map((e) => (
                                                                    <Index.MenuItem
                                                                        key={e}
                                                                        value={e._id}
                                                                        className="drop-menuitem"
                                                                    >
                                                                        {e.name}
                                                                    </Index.MenuItem>
                                                                ))}
                                                            </Index.Select>
                                                        </Index.FormControl>
                                                    </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="input-box modal-input-box">
                                                    <Index.FormHelperText className="form-lable">
                                                        Image {item?.image ? <><Index.Button onClick={async (event) => {
                                                            await uploadImage(null, item?.image)
                                                            setFieldValue(`dynamicSection[${index}].image`, '');
                                                            event.target.files = null;
                                                            event.target.value = null;
                                                        }}>Remove Image</Index.Button></> : ""}
                                                    </Index.FormHelperText>
                                                    <Index.Box className="form-group">
                                                        <img key={index} src={item?.image ? IMG_URL + item?.image : ""} alt="upload image" height={"100px"} width={"100px"} />
                                                        <Index.Button
                                                            fullWidth
                                                            variant="contained"
                                                            component="label"
                                                            type="button"
                                                            id="fullWidth"
                                                            className="form-control"
                                                            placeholder=""
                                                        >
                                                            Upload
                                                            <input
                                                                type="file"
                                                                hidden
                                                                id="authorImagePayload" name="authorImagePayload"
                                                                onChange={async (event) => {
                                                                    const imageUrl = await uploadImage(event.currentTarget.files[0], item?.image || "")
                                                                    alert(IMG_URL + imageUrl)
                                                                    setFieldValue(`dynamicSection[${index}].image`, imageUrl);
                                                                    event.target.files = null;
                                                                    event.target.value = null;
                                                                }}
                                                            />
                                                        </Index.Button>
                                                    </Index.Box>
                                                </Index.Box>

                                                <Index.Box className="input-box modal-input-box">
                                                    <Index.FormHelperText className="form-lable">
                                                        Select Story
                                                    </Index.FormHelperText>
                                                    <Index.Box className="dropdown-box">
                                                        <Index.FormControl className="form-control drop-form-control">
                                                            <Index.Select
                                                                className="dropdown-select drop-select"
                                                                value={item.storyId}
                                                                name={`dynamicSection[${index}].storyId`}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                helperText={touched.storyId && errors.storyId}
                                                                error={Boolean(errors.storyId && touched.storyId)}
                                                            >
                                                                <Index.MenuItem value="" className="drop-menuitem" disabled>
                                                                    Select story
                                                                </Index.MenuItem>
                                                                {storiesData?.map((e) => (
                                                                    <Index.MenuItem
                                                                        key={e._id}
                                                                        value={e._id}
                                                                        className="drop-menuitem"
                                                                    >
                                                                        {e.name}
                                                                    </Index.MenuItem>
                                                                ))}
                                                            </Index.Select>
                                                        </Index.FormControl>
                                                    </Index.Box>
                                                </Index.Box>
                                                {
                                                    (values?.dynamicSection?.length - 1) === index ? <Index.Button
                                                        endIcon={<AddCircleOutlineIcon />}
                                                        variant="contained"
                                                        onClick={() =>
                                                            push({
                                                                title: "",
                                                                text: "",
                                                                content: "",
                                                                scroll: "",
                                                                color: "#000000" // Default value for new sections
                                                            })
                                                        }
                                                    >
                                                        Add Section
                                                    </Index.Button> : ""
                                                }

                                            </div>
                                        ))}
                                    </div>
                                )}
                            </FieldArray>
                            <Index.Box className="modal-user-btn-flex">
                                <Index.Box className="discard-btn-main border-btn-main">
                                    <Index.Button onClick={() => {
                                        navigate(-1)
                                    }} type="reset" className="discard-user-btn border-btn">
                                        Discard
                                    </Index.Button>
                                </Index.Box>
                                <Index.Box className="save-btn-main border-btn-main">
                                    <Index.Button
                                        type="submit"
                                        className="save-user-btn border-btn"
                                    >
                                        <img
                                            src={PagesIndex.Svg.save}
                                            className="user-save-icon"
                                        ></img>
                                        Save
                                    </Index.Button>
                                </Index.Box>
                            </Index.Box>
                        </form>
                    )}
                </Formik> : "Loading..."
            }
        </>
    )
}