const Api = {
    Login: "admin/login",
    CategoryList: "admin/get-product-category",
    AddCategory: "admin/add-product-category",
    DeleteCategory: "admin/delete-product-category",

    UploadSingle: "admin/image-upload",

    TagsList: "admin/get-tags",
    AddTags: "admin/add-tags",
    DeleteTags: "admin/delete-tags",

    StoryList: "admin/get-stories",
    AddStory: "admin/add-stories",
    DeleteStory: "admin/delete-stories",

    BooksList: "admin/get-books",
    AddBooks: "admin/add-books",
    DeleteBooks: "admin/delete-books",

    BlogsList: "admin/get-blogs",
    BlogsSingle: "admin/get-single-blogs",
    AddBlogs: "admin/add-blogs",
    DeleteBlogs: "admin/delete-blogs",

    QuesttionList: "admin/get-questions",
    AddQuesttion: "admin/add-questions",
    DeleteQuesttion: "admin/delete-questions",

    DoctorsList: "admin/get-doctors",
    AddDoctors: "admin/add-doctors",
    DeleteDoctors: "admin/delete-doctors",

    FAQList: "admin/get-faq",
    AddFAQ: "admin/add-faq",
    DeleteFAQ: "admin/delete-faq",

    DownloadList: "admin/get-user-download-details",
    AppointmentList: "admin/get-user-appointment",

    SubCategoryList: "admin/get-sub-product-category",
    AddSubCategory: "admin/add-sub-product-category",
    DeleteSubCategory: "admin/delete-product-sub-category",
    GetProducts: "admin/get-product",
    AddProduct: "admin/add-product",
    DeleteProduct: "admin/delete-product",
    GetSlider: "admin/get-slider",
    AddSlider: "admin/add-slider",
    DeleteSlider: "admin/delete-slider",
    GetBanner: "admin/get-banner",
    AddBanner: "admin/add-banner",
    DeleteBanner: "admin/delete-banner",
    GetAllCodes: "admin/lis-all-offers",
    AddOffers: "admin/manage-discount",
    DeleteOffers: "admin/delete-offers",
    GetProductsCategoryWithSub: "admin/get-all-products-category-sub"
};
export { Api };
