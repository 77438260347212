import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'


export default function Otp() {
      return (
            <div>
                  <Index.Box className="admin-login-main-flex">
                        <Index.Box className="admin-login-left-main">
                        <PagesIndex.AuthBackground/>
                        </Index.Box>
                        <Index.Box className="admin-login-right-main">
                              <Index.Box className="admin-login-box">
                                    <Index.Box className="admin-login-main">
                                          <Index.Box className="admin-login-inner">
                                                <Index.Typography component="h2" variant='h2' className='admin-wel-text'>Otp!</Index.Typography>
                                                <Index.Typography component="p" variant='p' className='admin-sign-para common-para'>Please enter your credentials to Otp!</Index.Typography>
                                                <Index.Box className="otp-flex-main">
                                                      <Index.Box className="input-box otp-input-box">
                                                            <Index.Box className="form-group">
                                                                  <Index.TextField
                                                                        fullWidth
                                                                        id="fullWidth"
                                                                        className="form-control"
                                                                        placeholder="0"
                                                                        autocomplete="off"
                                                                  />
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="input-box otp-input-box">
                                                            <Index.Box className="form-group">
                                                                  <Index.TextField
                                                                        fullWidth
                                                                        id="fullWidth"
                                                                        className="form-control"
                                                                        placeholder="0"
                                                                        autocomplete="off"
                                                                  />
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="input-box otp-input-box">
                                                            <Index.Box className="form-group">
                                                                  <Index.TextField
                                                                        fullWidth
                                                                        id="fullWidth"
                                                                        className="form-control"
                                                                        placeholder="0"
                                                                        autocomplete="off"
                                                                  />
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="input-box otp-input-box">
                                                            <Index.Box className="form-group">
                                                                  <Index.TextField
                                                                        fullWidth
                                                                        id="fullWidth"
                                                                        className="form-control"
                                                                        placeholder="0"
                                                                        autocomplete="off"
                                                                  />
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="btn-main-primary login-btn-main">
                                                      <Index.Button className="btn-primary login-btn">Send</Index.Button>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>
            </div>
      )
}
